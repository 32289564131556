html {
  --grey-color: #6D6D6D;
  --blue-color: #0C7CC4;
  --blue-color-hover: #0A619A;
  --reports-links: #0C7CC4;
}

body {
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000;
}

h3 {
  font-family: 'Arial', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000;

  @media (min-width: 1367px) {
    font-size: 16px;
  }
}

.headline-1 {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #000;
}

.headline-2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333;

  @media (min-width: 1367px) {
    font-size: 16px;
    line-height: 18px;
  }
}

.body-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #333;
}

.body-text-2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333;

  @media (min-width: 1367px) {
    font-size: 16px;
    line-height: 18px;
  }
}

.body-text-3 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #333;

  @media (min-width: 1367px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.sprite-icon {
  fill: #fff;
}

label {
  font-family: 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--grey-color);
  margin-bottom: 5px;

  @media (min-width: 1367px) {
    font-size: 14px;
    line-height: 16px;
  }
}

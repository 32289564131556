#root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
  height: 100vh;
}

.content-wrap {
  grid-area: main;
  position: relative;
  display: flex;
  flex: 1 0 auto;
  overflow: hidden;
}

.full-page {
  margin: 10px;
  padding: 15px;
  background-color: rgb(255, 255, 255);
}

.elevation-block {
  background: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  transition: 0.4s ease-in-out;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
  }
}

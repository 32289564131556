.report-chart-top-ten {
  .MuiFormControl-root {
    border: none;
    min-width: 120px;
    margin: 15px;
    .MuiInputBase-input {
      padding: 7px 30px 7px 15px;
    }
  }
}

@media (max-width: 1366px) {
  .report-chart-top-ten {
   .MuiFormControl-root {
     min-width: 80px;
     margin: 5px;
     .MuiInputBase-input {
       padding: 7px 30px 7px 15px;
     }
   }
  }
}

.filter-link {
  color: var(--reports-links);
  text-decoration: none;
  transition: 1s ease;
  &:hover {
    transition: 1s ease;
    color: var(--reports-links);
    text-decoration: underline;
  }
}

.report-graphs-modal {
  .menu-btn {
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000;
    text-transform: unset !important;

    @media (min-width: 1367px) {
      font-size: 14px;
    }

    svg {
      width: 15px;
      height: 8px;
      stroke: var(--blue-color);
      margin-left: 5px;
    }
  }
}
.report-container {
  .profiling-btn {
    border-radius: 20px;
    padding: 10px 25px;
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000;
    text-transform: unset !important;
    font-weight: bold;
    border: 1px solid var(--blue-color);

    &:hover {
      border-color: var(--blue-color-hover)
    }
  }
}

.records-selected {
  color: #6D6D6D;
  span{
    color: #000;
    background: #F2F2F2;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    display: inline-flex;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
  }
}
table{
  td, th {
    &.freeze {
      position: sticky;
      left: 0;
      right: 0;
      z-index: 10;
      background-color: #fff;
    }
  }
  th {
    &.freeze {
      z-index: 11;
    }
  }
}

.mapPanel {
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  p {
    margin: 0 0 10px 0;
  }

  .actions {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    background-color: #fff;
    white-space: nowrap;
    z-index: 1;
  }

  .panel-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--grey-color);
    margin-bottom: 0;
  }

  .panel-title {
    color: #000;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .panel-type {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;

    button {
      font-family: 'Arial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #000;
      border: 1px solid #E0E0E0 !important;
      text-transform: capitalize !important;
      width: 95px;
      height: 32px;
      border-radius: 3px;

      @media (min-width: 1367px) {
        font-size: 14px;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        border-color: var(--blue-color) !important;
      }
    }
  }

  .panel-filter {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #6D6D6D;
    label {
      display: block;
    }
    &.type{
      margin: 5px 0;
      p {
        text-transform: capitalize;
      }
    }

    p {
      padding: 0;
      margin: 0;
      font-size: 12px;
      color: #000;

      @media (min-width: 1367px) {
        font-size: 14px;
      }
    }
  }

  .divider {
    width: 100%;
    border: none;
    border-top: 1px solid #F2F2F2;
  }
}

.info-popper {
  .MuiPopover-paper {
    background-color: #f7f9d2;
    padding: 15px;
  }

  .info-content {
    max-width: 300px;
    font-size: 14px;

    p {
      margin-top: 0;
      margin-bottom: 5px;
    }

    span {
      margin-left: 5px;

      &:not(:last-of-type) {
        margin-right: 5px;
      }

      + span {
        position: relative;

        &:not(:nth-child(2)) {
          &:before {
            content: ",";
            position: absolute;
            left: -10px;
          }
        }
      }
    }

    .label {
      color: var(--grey-color);
      margin: 0;
    }
  }
}

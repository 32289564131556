.MuiInputBase-input {
  font-family: 'Arial', sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: normal !important;
  color: #000 !important;

  @media (min-width: 1367px) {
    font-size: 14px !important;
  }
}

.btn-rounded {
  background: #fff;
  border: 1px solid var(--blue-color);
  border-radius: 500px;
  padding: 5px 10px;
}

.form-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--grey-color);
  margin-top: 10px;

  @media (min-width: 1367px) {
    font-size: 14px;
  }
}

.form-select, .form-input {
  width: 100%;
  margin-top: 5px;
  [class*=MuiFormHelperText] {
    margin: 4px 0 0 0;
    font-size: 10px;
  }
}

.form-input {
  .MuiInputBase-input {
    height: 32px !important;
    padding-top: 0 !important;
    padding-bottom: 0;

    &::placeholder {
      color: var(--grey-color);
      opacity: 1;
    }
  }
}

.custom-select {
  margin-top: 0 !important;

  .MuiSelect-select {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    min-height: auto !important;
  }

  .sprite-icon {
    position: absolute;
    width: 10px;
    height: 6px;
    stroke: var(--blue-color);
    right: 10px
  }

  .reset-button {
    position: absolute;
    right: 5px;

    .sprite-icon {
      position: relative;
      stroke: var(--grey-color) !important;
      right: unset;
      width: 8px;
      height: 8px;
    }
  }

  input {
    color: #000 !important;
    height: 100% !important;
  }

  .placeholder {
    color: var(--grey-color);
    opacity: 1;
  }
}

.form-date {
  display: flex;
  flex-direction: column;

  .MuiInputBase-input {
    height: 32px !important;
    padding: 0 0 0 10px !important;
    font-size: 13px !important;

    &::placeholder {
      color: var(--grey-color) !important;
      opacity: 1 !important;
    }
  }

  svg {
    width: 15px;
  }
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

.css-b3avxn-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}

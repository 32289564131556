.column-search-menu-wrap {
  display: inline;
  position: relative;

  .searchBtn {
    width: 15px;
    height: 15px;
    text-align: center;
    margin-right: 10px;
    padding: 0;
  }

  .sprite-icon {
    stroke: var(--grey-color);
  }

  .sprite-filter {
    stroke: var(--blue-color) !important;
    fill: var(--blue-color) !important;
    width: 15px;
  }
}

.column-search-menu {
  position: relative;
  margin-top: -15px;
  margin-left: -15px;

  .sprite-search {
    position: absolute;
    left: 15px;
    top: 15px;
    width: 15px;
    height: 15px;
    stroke: var(--blue-color);
  }

  .clear-search {
    position: absolute;
    top: 5px;
    right: 5px;

    .sprite-icon {
      width: 24px;
      height: 24px;
      opacity: 0.7;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .form-input {
    .MuiInput-input {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  table {
    width: 100%;

    td, th {
      padding: 5px 15px;
      white-space: nowrap;
    }

    tr {
      td:first-of-type {
        padding: 5px;
      }
    }
  }
}

.filter-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;

  .text {
    white-space: nowrap;
    margin-right: 15px;
  }
}

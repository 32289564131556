.action-summary {
  &-btn {
    position: absolute;
    left: 0;
    bottom: 40%;
    padding: 10px;
    animation: slideIn 0.5s ease-in-out;
    writing-mode: vertical-lr;
    transform: scale(-1, -1);
    border-radius: 3px;
    border: 1px solid  #F2F2F2;
    background: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
  &-close {
    position: absolute !important;
    right: 15px;

    .sprite-icon {
      position: relative;
      stroke: var(--grey-color) !important;
      right: unset;
      width: 8px;
      height: 8px;
    }
  }
}

.animated-div {
  position: absolute;
  left: 0;
  bottom: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  animation: slideIn 0.5s ease-in-out;
  border-radius: 3px;
  background: #FFF;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

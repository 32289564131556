.colors-settings {
  overflow: auto;

  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      font-family: 'Arial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: var(--grey-color);
      text-align: center;

      &:first-child {
        text-align: left;
      }

      @media (min-width: 1367px) {
        font-size: 14px;
      }
    }

    th {
      padding-bottom: 15px;
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
      border-top: 1px solid #E0E0E0;
      border-bottom: 1px solid #E0E0E0;
    }
  }

  tr.active {
    td {
      position: relative;
      border-top-color: #000;
      border-bottom-color: #000;
      box-sizing: border-box;

      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        background-color: #000;
        top: -1px;
        left: -1px;
        width: 100%;
      }
    }
  }

  .color-circle {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid #E0E0E0;
    border-radius: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }
}

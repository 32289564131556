.menuLanguage {
  margin-right: 15px;
  cursor: pointer;

  span {
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    color: #000;

    @media (min-width: 1367px) {
      font-size: 14px;
    }
  }

  svg {
    width: 12px;
    height: 12px;
    margin-right: 10px;
    fill: var(--grey-color);

    @media (min-width: 1367px) {
      width: 15px;
      height: 15px;
    }
  }
}

.language-menu {
  li {
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    color: #000;

    @media (min-width: 1367px) {
      font-size: 14px;
    }
  }
}

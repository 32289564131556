.add-note {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    margin: auto!important;
  }

  p {
    margin: 0;
  }

  &__header {
    padding: 18px 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;

    &__close {
      position: absolute !important;
      right: 15px;

      .sprite-icon {
        position: relative;
        stroke: var(--grey-color) !important;
        right: unset;
        width: 8px;
        height: 8px;
      }
    }
  }

  &__subheader {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F0F6FF;
  }

  &__note {
    &-count {
      color: #0C7CC4;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }

  &__date {
    color: #64748B;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__form {
    padding: 10px 15px;

    p {
      font-weight: bold;
      margin-bottom: 10px;
    }

    textarea {
      display: block;
      border-radius: 8px;
      border: 0.8px solid #0C7CC4;
      resize: none;
      min-width: 270px;
      min-height: 120px;
      padding: 12px;
      font-size: 12px;

      &::placeholder {
        opacity: 0.5;
      }
    }

    &-btn {
      display: flex;
      justify-content: end;
      button {
        margin: 10px 0;
      }
    }
  }

  &__list {
    flex: 1;
    padding: 15px;
    background: #F0F6FF;
    overflow: auto;

    &-item {
      padding: 12px;
      width: 100%;
      background: #fff;
      border: 1px solid #F0F0F0;
      border-radius: 8px;
      margin-bottom: 10px;

      &.active {
        border: 1px solid #0C7CC4;
      }

      &-divider {
        margin: 8px 0;
        width: 100%;
        height: 1px;
        opacity: 0.2;
        background: #B0B7C3;
      }

      &-header {
        color: #64748B;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-actions {
          display: flex;
        }
      }

      textarea {
        border: none;
        outline: none;
        resize: none;
        background: #fff;
        color: #1E1E1E;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        width: 100%;
      }
    }
  }
}

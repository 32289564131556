.app-header {
  grid-area: header;
  border-bottom: 1px solid #F2F2F2;;
}

.header-wrap {
  flex-basis: 52px;
  background-color: #fff;
}

.mainMenu {
  padding-left: 30px !important;
  padding-right: 10px !important;

  a:not(.menuBtn) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 52px;
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--grey-color);
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: 2px solid transparent;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &.active {
      color: #000;
      border-bottom: 2px solid var(--blue-color);
    }
  }

  button {
    color: #000;
  }
}

.menuBtn {
  display: flex;
  align-items: center;
  font-family: 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--grey-color);
  text-decoration: none;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-left: 10px;
    margin-right: 10px;
  }

  &.active {
    color: #000;
  }

  @media (min-width: 1367px) {
    font-size: 16px;
  }
}

.user-menu {
  li {
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000;

    @media (min-width: 1367px) {
      font-size: 14px;
    }
  }
}

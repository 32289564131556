.report-chart-performance {
  .MuiFormControl-root {
    margin: 15px;
    border: none;
    &:nth-last-child(2) {
      margin: 15px 0;
    }
    .MuiInputBase-input {
      max-width: 100px;
      min-width: 35px;
      padding: 7px 25px 7px 15px;
    }
  }
}

@media (max-width: 1366px) {
  .report-chart-performance {
   .MuiFormControl-root {
     margin: 5px;
     &:nth-child(2) {
       margin: 5px 0;
     }
     .MuiInputBase-input {
       padding: 7px 25px 7px 15px;
     }
   }
  }
}

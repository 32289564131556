@font-face {
  font-family: 'Arial';
  font-style: normal;
  font-weight: normal;
  src: local('Arial Regular'), url('ARIAL.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  font-style: italic;
  font-weight: normal;
  src: local('Arial Italic'), url('ARIALI.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 500;
  src: local('Arial Medium'), url('ArialMdm.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  font-style: italic;
  font-weight: 500;
  src: local('Arial Medium Italic'), url('ArialMdmItl.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  src: local('Arial Bold'), url('ARIALBD.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  font-style: italic;
  font-weight: 700;
  src: local('Arial Bold Italic'), url('ARIALBI.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 900;
  src: local('Arial Black'), url('ARIBLK.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  font-style: italic;
  font-weight: 900;
  src: local('Arial Black Italic'), url('ARIALBLACKITALIC.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 300;
  src: local('Arial Light Regular'), url('ARIALLGT.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  font-style: italic;
  font-weight: 100;
  src: local('Arial Light Italic'), url('ARIALLGTITL.woff') format('woff');
}

.help-content {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  padding: 20px 20px 10%;
  width: 100%;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;

  .elevation-block {
    padding: 40px 30px;
    text-align: center;
    cursor: pointer;
    height: 100%;

    h4 {
      font-weight: 700;
      font-size: 18px;
      color: #000000;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      color: var(--grey-color);
      white-space: pre-line;
    }
  }
}

.circle-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: #F2F2F2;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  .sprite-icon {
    width: 30px;
    color: var(--grey-color);
  }
}

.hotsheets {
  .section-header {
    display: flex;
    justify-content: space-between;

    button {
      font-family: 'Arial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #000;
      border: 1px solid #E0E0E0 !important;
      margin-left: 5px;
      margin-right: 5px;
      text-transform: capitalize !important;

      @media (min-width: 1367px) {
        font-size: 14px;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        border-color: var(--blue-color) !important;
      }

      &.circle-button {
        width: 30px;
        height: 30px;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #64748B!important;
        cursor: pointer;


        .dots-container {
          display: flex;
          align-items: center;
          justify-content: center;

          .dot {
            width: 3px;
            height: 3px;
            background-color: #64748B;
            border-radius: 50%;
            margin: 0 2px;
          }
        }
      }
    }
  }
  .report-container {
    width: 100%;
    table {
      tr {
        td, th {
          border-bottom: 1px solid #f2f2f2;
          color: var(--grey-color);
          font-family: Arial,sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          padding: 5px 15px;
          white-space: nowrap;
        }
      }
    }
  }
}



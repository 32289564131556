.filters-wrap {
  box-sizing: border-box;
  position: relative;
  width: 330px;
  height: 100%;
  left: 0;
  top: 0;
  background: #fff;
  border-top: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;
  transition: 0.4s ease-in-out;

  .filters-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }

  .filters-footer {
    position: absolute;
    width: 330px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    border-top: 2px solid #F2F2F2;
    background-color: #fff;
    z-index: 1;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    @media (min-width: 1367px) {
      font-size: 16px;
    }
  }

  .line {
    position: relative;
    margin-left: auto;

    &:before {
      content: '';
      position: absolute;
      display: block;
      background-color: #000;
      width: 2px;
      height: 18px;
      right: 8px;
      top: -8px;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      background-color: #000;
      width: 18px;
      height: 2px;
      right: 0;
    }
  }

  .Mui-expanded {
    margin: 0 !important;
    min-height: 1px !important;

    .line {
      &:before {
        display: none;
      }
    }
  }

  .sprite-collapse {
    width: 15px;
  }
}

.filters {
  position: relative;
  width: 270px;
  margin: 15px 30px 0;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100% - 120px);

  form {
    position: absolute;
    overflow-y: scroll;
    padding-right: 20px;
    height: 100%;
    padding-bottom: 20px;
  }
}

.open-filters {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  width: 30px;
  cursor: pointer;

  .open-filters-title {
    display: flex;
    width: 30px;
    height: auto;
    writing-mode: vertical-lr;
    text-orientation: mixed;
  }

  span {
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #000;
    transform: rotate(180deg);
    transform-origin: 50% 50%;

    @media (min-width: 1367px) {
      font-size: 14px;
    }
  }
}

.report-container {
  transform: translateX(0);
  width: calc(100% - 330px);
  transition: 0.4s ease-in-out;

  .menu-button {
    text-transform: none;
    color: var(--reports-links);
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    padding-right: 25px;

    &:hover {
      .menu-arrow {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .menu-arrow {
    display: none;
    font-size: 18px
  }
}

[data-filters-opened] {
  transition: 0.4s ease-in-out;
}

[data-filters-opened="false"] {
  transform: translateX(0) !important;

  .filters-wrap {
    transform: translateX(-300px) !important;
  }

  .open-filters {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      transform-origin: 50% 50%;
      transform: rotate(180deg);
      color: var(--blue-color);
      width: 15px;
      height: 15px;
      margin-top: 5px;
    }
  }

  .report-container {
     transform: translateX(-300px);
     width: calc(100% - 30px);
  }
}

.map-wrap {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 100px;

  button {
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Mui-expanded + .MuiCollapse-root {
  margin-bottom: 10px !important;
}

.MuiAccordion-root:before {
  display: none !important;
}

.account-wrap {
  display: grid !important;
  grid-template-areas: 'account-header'
                      'content'
                      'account-footer';
  grid-template-rows: auto minmax(0, 1fr) auto;
  margin: 10px;
  background-color: #fff;

  .section-header {
    grid-area: account-header;

    button {
      font-family: 'Arial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #000;
      border: 1px solid #E0E0E0 !important;
      margin-left: 5px;
      margin-right: 5px;
      text-transform: capitalize !important;

      @media (min-width: 1367px) {
        font-size: 14px;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        border-color: var(--blue-color) !important;
      }
    }
  }

  .account-details {
    display: grid;
    grid-area: content;
    grid-template-columns: 1fr 1fr;

    > div + div {
      border-left: 1px solid #F2F2F2;
    }
  }

  .account-footer {
    grid-area: account-footer;
    margin-top: auto;
    width: 100%;
    padding: 0 15px;
    text-align: center;
    border-top: 1px solid #F2F2F2;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--grey-color);
  }

  .user-data {
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000;

    @media (min-width: 1367px) {
      font-size: 16px;
    }
  }

  .logo {
    max-width: 100%;
    max-height: 80px;
  }

  .submit {
    display: flex;
    justify-content: flex-end;
  }
}

.section-header {
  display: flex;
  align-items: center;
  height: 52px;
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid #F2F2F2;
}

.settings-wrap {
  display: block !important;
  margin: 10px;
  background-color: #fff;

  .account-settings {
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .MuiFormControlLabel-label {
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--grey-color);

    @media (min-width: 1367px) {
      font-size: 14px;
    }
  }
}

.half-block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;

  form {
    width: 260px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.row {
  display: flex;
  gap: 10px;
}

.payment-wrap {
  width: 100%;
  max-width: 900px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;

  .elevation-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 150px;

    button {
      margin-top: auto;
    }
  }
}

.payment-text {
  p {
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    margin-top: 0;
    margin-bottom: 5px;

    @media (min-width: 1367px) {
      font-size: 16px;
    }
  }
}

.invoice-text {
  p {
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--grey-color);
    margin-top: 0;
    margin-bottom: 5px;

    @media (min-width: 1367px) {
      font-size: 16px;
    }
  }
}

.invoice-table-wrap {
  overflow-x: auto;
  margin-top: 15px;

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      font-family: 'Arial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: var(--grey-color);
      background: #F2F2F2;
      border-right: 2px solid #fff;
      padding: 10px 10px 0;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      @media (min-width: 1367px) {
        font-size: 16px;
      }
    }

    td {
      font-family: 'Arial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #000;
      background: #F2F2F2;
      border-right: 2px solid #fff;
      padding: 10px;

      @media (min-width: 1367px) {
        font-size: 14px;
      }
    }

    tr:last-of-type {
      td {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
}

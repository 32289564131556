.reports-wrap {
  transition: 0.4s ease-in-out;
  left: 0;
  width: 100%;


  .agent-history,
  .agent-transactions,
  .office-transactions,
  .firm-transactions,
  .brand-transactions,
  .agent-performance,
  .office-performance,
  .firm-performance,
  .brand-performance,
  .agent-performance-distribution,
  .office-performance-distribution,
  .firm-performance-distribution,
  .brand-performance-distribution,
  .market-performance-distribution,
  .market-comparative-distribution,
  .agent-distribution,
  .office-distribution,
  .firm-distribution,
  .brand-distribution {
    th, td {
      height: 40px;
    }
  }

  table {
    width: 100%;
    tr{
      &.Mui-selected {
        background: none!important;
        td {
          background: #ecf2fb!important;
          &.freeze{
            background: #ecf2fb!important;
          }
        }
      }
      &:hover {
        background: none!important;
        td {
            background: rgba(0, 0, 0, 0.04)!important;
          &.freeze{
            background: #f5f5f5!important;
          }
          &.progress-content {
            background: none!important;
          }
        }
      }
      &.totals-row {
        &.Mui-selected {
          background: none!important;
          td {
            background: #ecf2fb!important;
            &.freeze{
              background: #ecf2fb!important;
            }
          }
        }
        &:hover {
          background: none!important;
          td {
            background: #f5f5f5!important;
            &.freeze{
              background: #f5f5f5!important;
            }
            &.progress-content {
              background: none!important;
            }
          }
        }
      }
    }
    th {
      font-family: 'Arial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: var(--grey-color);
      border-bottom: 1px solid #F2F2F2;
      white-space: nowrap;
      padding: 5px 15px;

      &:nth-child(2) {
        padding-left: 0;
      }

      @media (min-width: 1367px) {
        font-size: 14px;
      }

      .sprite-sort-icon {
        transform-origin: 50% 50%;
        transition: 0.4s ease-in-out;
        fill: #6D6D6D;
      }

      &[aria-sort="descending"] {
        .sprite-sort-icon {
          fill: var(--blue-color) !important;
        }
      }

      &[aria-sort="ascending"] {
        .sprite-sort-icon {
          transform: rotate(180deg);
          fill: var(--blue-color) !important;
        }
      }
    }

    td {
      font-family: 'Arial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: var(--grey-color);
      white-space: nowrap;
      border-bottom: 1px solid #F2F2F2;
      padding: 5px 15px;

      @media (min-width: 1367px) {
        font-size: 14px;
      }
    }

    tr:not(:last-of-type) {
      td:nth-child(2) {
        padding-left: 0;
      }
    }

    tbody {
      tr:last-of-type {
        position: -webkit-sticky;
        position: sticky;
        background: #fff;
        bottom: 0;
        z-index: 10;

        td {
          border-bottom: none;
        }
      }
    }
  }

  .report {
    display: grid;
    grid-auto-rows: auto 1fr auto;
    height: calc(100% - 72px);
    margin: 10px;
    background-color: #fff;
    transition: 0.4s ease-in-out;

    &:has(.report-chart) {
      grid-auto-rows: auto auto 1fr;
    }
  }

  .report-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 5px 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
    border-bottom: 1px solid #F2F2F2;
    padding: 5px 15px;
    z-index: 1;

    &-top-ten,
    &-performance{
      flex: 0 0 40px;
    }

    .report-header-top {
      display: flex;
      flex-direction: column;
      align-items: end;
    }

    .report-header-middle {
      display: flex;
      align-items: center;

      span {
        color: var(--grey-color);
        margin-right: 5px;
      }
    }

    .report-header-bottom {
      display: flex;
      flex-direction: column;
      align-items: end;
    }

    .report-name {
      display: inline-flex;
      align-items: center;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #000;

      @media (min-width: 1367px) {
        font-size: 16px;
      }
    }

    .report-date {
      margin-left: 10px;
      font-size: 14px;
      color: #000;

      @media (min-width: 1367px) {
        font-size: 16px;
      }

      span {
        color: var(--grey-color);
      }
    }
  }

  .report-chart-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 5px 10px;
    border-bottom: 1px solid #F2F2F2;
    padding: 5px 15px;

    .report-chart-panel {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .report-chart {
    overflow: auto;
    padding: 0 15px;
  }
}

.empty-report {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .circle-wrap {
    width: 64px;
    height: 64px;
  }

  p {
    white-space: pre-line;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    color: #E12329;
    line-height: 1;
  }

  svg {
    width: 25px;
    fill: #087DC2;
  }
}

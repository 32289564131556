.filled-btn {
  background: var(--blue-color) !important;
  border-radius: 500px !important;
  font-family: 'Arial', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize !important;
  height: 36px;
  padding-left: 45px !important;
  padding-right: 45px !important;
  color: #fff !important;
  box-sizing: border-box;
  border: 1px solid transparent!important;

  &:hover {
    border: 1px solid rgba(255, 66, 1, 0.5);
    background-color: var(--blue-color-hover) !important;
  }

  &:disabled {
    background-color: #E0E0E0 !important;
  }

  &.red {
    background-color: #FF4201 !important;

    &:hover {
      background-color: rgba(255, 66, 1, 0.75) !important;
    }
  }

  @media (min-width: 1367px) {
    font-size: 16px;
  }
}

.outlined-btn {
  min-width: auto !important;
  background: #fff;
  border: 1px solid var(--blue-color)!important;
  border-radius: 500px !important;
  font-family: 'Arial', sans-serif;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-transform: capitalize !important;
  color: #000 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

  &:hover {
    border-color: var(--blue-color-hover)!important;
  }
}

.text-btn {
  font-family: 'Arial', sans-serif;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize !important;
  color: #000 !important;

  @media (min-width: 1367px) {
    font-size: 16px;
  }
}

.menu-item {
  font-size: 12px !important;

  @media (min-width: 1367px) {
    font-size: 14px !important;
  }

  button {
    margin-left: auto;

    .sprite-icon {
      width: 10px;
      height: 10px;
    }
  }
}

.font-weight-bold {
  font-weight: bold;
}

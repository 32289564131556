.report-menu-item {
  font-family: 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 14px;
  color: var(--grey-color);

  @media (min-width: 1367px) {
    font-size: 14px !important;
  }

  .sprite-icon {
    width: 10px;
    height: 8px;
    transform: rotate(-90deg);
    margin-left: auto;
    stroke: var(--blue-color);
  }
}

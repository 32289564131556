.info-button {
    padding: 0 !important;
    cursor: help !important;

    .info {
        width: 15px;
        margin-left: 10px;
        fill: var(--blue-color);
    }
}

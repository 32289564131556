@import "./assets/styles/helpers";
@import "./assets/styles/typography";
@import "./assets/styles/layout";
@import "./assets/styles/buttons";
@import "./assets/styles/form";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
  background-color: #E0E0E0;
}

.Mui-error {
  white-space: pre-line;
}

.header-wrap {
  flex-basis: 52px;
}

.font-size-s {
  font-size: 12px!important;
}

.m-0 {
  margin: 0;
}

.m-auto {
  margin: auto;
}

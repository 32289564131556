.app-footer {
  grid-area: footer;
  border-top: 1px solid #F2F2F2;
}

.footerMenu {
  display: flex;
  justify-content: space-between;
  padding-left: 30px !important;
  padding-right: 10px !important;

  .copyright {
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000;

    @media (min-width: 1367px) {
      font-size: 16px;
    }
  }

  button {
    height: 100%;
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    text-transform: initial;
    color: #000;

    @media (min-width: 1367px) {
      font-size: 14px;
    }
  }
}

.coc-modal {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--grey-color);

    @media (min-width: 1367px) {
      font-size: 16px;
    }

    strong {
      color: #000 !important;
    }
  }
}

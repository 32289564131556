.progress-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .progress-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 100%;
    height: 100%;
  }

  .logo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 200px;
    height: 200px;
  }

  img, .MuiCircularProgress-indeterminate {
    position: absolute;
  }
}

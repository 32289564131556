.saved-search-widget {
  position: relative;
  margin-bottom: 5px;

  .MuiInputBase-input {
    padding: 0 25px 0 10px !important;
    font-family: 'Arial', sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: normal !important;
    color: #000 !important;
    height: 32px !important;

    @media (min-width: 1367px) {
      font-size: 14px !important;
    }
  }
}

.field-wrap {
  position: relative;

  svg {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    stroke: var(--blue-color);
    width: 10px;
    height: 6px;
  }

  .form-select {
    .MuiInputBase-input {
      &::placeholder {
        color: var(--grey-color) !important;
        opacity: 1 !important;
      }
    }
  }
}

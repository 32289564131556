.saved-searches-select {
  display: flex;
  flex-direction: column;
}

.save-search-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.26)!important;
  border-radius: 3px;
  width: 32px !important;
  height: 32px;
  margin-left: 8px !important;
  min-width: 32px !important;
  box-sizing: border-box;

  &:hover{
    border: 1px solid var(--blue-color-hover)!important;
  }

  span {
    margin: 0 !important;
  }

  svg {
    width: 12px;
    height: 16px;
  }
}

.report-table-pagination {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  bottom: 0;
  background: #fff;
  display: flex;
  flex: 1 0 52px;
  align-items: center;
  gap: 5px 10px;
  border-top: 1px solid #F2F2F2;
  padding: 5px 15px;
  min-width: 246px;

  .MuiPagination-ul {
    justify-content: space-evenly;

    .MuiButtonBase-root {
      margin: 0;
    }
  }

  .MuiOutlinedInput-root {
    min-height: 30px !important;
    height: 30px !important;
    background: #F2F2F2;

    fieldset {
      border: none !important;
      top: 0;
      padding: 0;
      line-height: 1;
    }
  }

  .MuiSelect-select {
    border: none !important;
    min-height: 1px;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    color: var(--grey-color);

    @media (min-width: 1367px) {
      font-size: 14px;
    }
  }
}

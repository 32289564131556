.report-types-panel {
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  background-color: #fff;
  border: 1px solid #F2F2F2;
  border-left-width: 2px;
  padding-right: 10px;
  transition: 0.4s ease-in-out;

  & > li.report-menu-item {
    background: none;
    height: 100%;
    &:hover {
      background-color: rgba(25, 118, 210, 0.04);
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      right: 0;
      top: 0;
      bottom: 0;
      height: 42px;
      margin-top: auto;
      margin-bottom: auto;
      border: 1px solid #F2F2F2;
    }
  }

  .menu-btn {
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000;
    text-transform: unset !important;

    @media (min-width: 1367px) {
      font-size: 14px;
    }

    svg {
      width: 15px;
      height: 8px;
      stroke: var(--blue-color);
      margin-left: 5px;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      right: 0;
      top: 0;
      bottom: 0;
      height: 42px;
      margin-top: auto;
      margin-bottom: auto;
      border: 1px solid #F2F2F2;
    }
  }

  .export-btn {
    margin-left: 10px;
    border: 1px solid var(--blue-color);
    &[class*=-loading] {
      color: transparent!important;
    }
    &:hover {
      border-color: var(--blue-color-hover)
    }
  }

  .settings-btn {
    width: 32px !important;
    height: 32px !important;
    background-color: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    padding: 0 !important;
    min-width: 32px;
    margin-left: 10px;

    &:hover{
      border: 1px solid var(--blue-color-hover);
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  .report-type-select {
    min-width: auto;
    height: 32px !important;
    margin-left: 10px;
    background: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 3px;

    .MuiSelect-select {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      min-height: auto !important;
    }

    .sprite-icon {
      position: absolute;
      width: 10px;
      height: 6px;
      stroke: var(--blue-color);
      right: 10px;
    }
  }
}

.columns-settings-modal {
  h2 {
    font-size: 14px;

    @media (min-width: 1367px) {
      font-size: 16px;
    }
  }
}

.count {
  display: inline-flex;
  align-items: center;

  span {
    color: #000;
    background: #f2f2f2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
}
